import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Presentation from "../components/Presentation";
import ScrollTop from "../components/ScrollTop";

const Tools = () => {
  return (
    <div className="main">
      <Header />
      <Presentation />
      <div className="tools-container">
        <h3>What I Use to Build</h3>
        <div className="tools">
          <div className="code">
            <h4>Work Environment</h4>
            <ul>
              <li>VSCode</li>
              <li>Git</li>
              <li>GitHub</li>
            </ul>
          </div>
          <div className="front">
            <h4>Front-end</h4>
            <ul>
              <li>HTML5 / CSS3</li>
              <li>JavaScript (ES6+)</li>
              <li>React.js</li>
              <li>Sass</li>
              <li>Tailwind</li>
              <li>bootstrap</li>
            </ul>
          </div>
          <div className="back">
            <h4>Back-end</h4>
            <ul>
              <li>Node.js</li>
              <li>Express.js</li>
            </ul>
          </div>
          <div className="fullstack">
            <h4>Fullstack</h4>
            <ul>
              <li>TypeScript</li>
              <li>Next.js</li>
            </ul>
          </div>
          <div className="db">
            <h4>Databases</h4>
            <ul>
              <li>MySQL</li>
              <li>MongoDB</li>
              <li>Prisma</li>
              <li>SQLite</li>
            </ul>
          </div>
          <div className="ai">
            <h4>AI</h4>
            <ul>
              <li>ChatGPT</li>
              <li>Github Copilot</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Tools;
