import React from "react";

const Footer = () => {
  const emailUsername = "jeyson.boursault";
  const emailDomain = "gmail.com";
  const email = `${emailUsername}@${emailDomain}`;

  return (
    <footer>
      <div className="contact">
        <h3>Contact</h3>
        <p>Connect with me on social media or drop me an email.</p>
      </div>
      <div className="social">
        <a
          href="https://github.com/jeyson08/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="./assets/github.svg" alt="github" />
        </a>
        <a
          href="https://linkedin.com/in/jeyson-boursault-a127301ba"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="./assets/linkedin.svg" alt="linkedin" />
        </a>
        <a href={`mailto:${email}`}>
          <img src="./assets/gmail.png" alt="linkedin" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
