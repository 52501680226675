import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Presentation from "../components/Presentation";
import ScrollTop from "../components/ScrollTop";

const Faq = () => {
  return (
    <div className="main">
      <Header />
      <Presentation />
      <div className="faq-container">
        <h3>Frequently Asked Questions</h3>
        <div className="faq">
          <div className="question-container">
            <p className="question">
              Es-tu ouvert à des missions de courte, moyenne ou longue durée ?
            </p>
            <p className="answer">
              Je suis ouvert à tout type de mission en freelance, que ce soit
              pour des projets de courte, moyenne ou longue durée. Je reste
              également ouvert à des opportunités en entreprise, notamment si
              elles offrent des défis techniques stimulants et un environnement
              propice à l'innovation. Mon objectif est de continuer à évoluer,
              que ce soit en freelance ou au sein d'une équipe structurée.
            </p>
          </div>
          <div className="question-container">
            <p className="question">
              Dans quelles zones géographiques es-tu disponible pour travailler
              ?
            </p>
            <p className="answer">
              Basé à Boulogne-sur-Mer, je suis disponible dans le Pas-de-Calais
              et à Lille pour des missions freelance, tout en étant parfaitement
              à l'aise et autonome en télétravail. Je suis également ouvert à
              des opportunités d'emploi en CDI à Lille.
            </p>
          </div>
          <div className="question-container">
            <p className="question">
              Comment te tiens-tu à jour sur les nouvelles technologies et
              frameworks ?
            </p>
            <p className="answer">
              Je me tiens à jour en suivant des comptes spécialisés sur les
              réseaux sociaux, notamment sur YouTube, où je découvre les
              dernières tendances et bonnes pratiques. En complément, je suis
              régulièrement des formations en ligne pour approfondir mes
              connaissances et maîtriser les nouvelles technologies et
              frameworks.
            </p>
          </div>
          <div className="question-container">
            <p className="question">
              Comment intègres-tu le front-end et le back-end dans tes projets
              fullstack ?
            </p>
            <p className="answer">
              Pour intégrer le front-end et le back-end dans mes projets
              fullstack, j'utilise des API REST pour connecter les deux. Côté
              back-end, j'utilise Node.js et Express pour gérer les requêtes et
              les bases de données. Côté front-end, j'utilise React pour
              consommer ces API. Je m'assure de la sécurité avec des systèmes
              d'authentification et teste régulièrement la communication entre
              les deux pour une intégration fluide.
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Faq;
