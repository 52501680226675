import React from "react";

const Stack = () => {
  return (
    <div className="stack">
      <div className="infos">
        <h3>My stack</h3>
        <p>
          I help you deliver seamless experiences for your customers by building
          fully custom applications from scratch.
        </p>
      </div>
      <div className="technos">
        <div className="react">
          <div className="img-container">
            <img src="./logo192.png" alt="react" />
          </div>
          <div className="content">
            <h4>React.js</h4>
            <p>
              I build fast, scalable, and dynamic web applications. I ensure a
              smooth and intuitive user experience.
            </p>
          </div>
        </div>
        <div className="sass">
          <div className="img-container">
            <img src="./assets/sass-logo.svg" alt="sass" />
          </div>
          <div className="content">
            <h4>Sass</h4>
            <p>
              I specialize in Sass, creating clean, efficient, and maintainable
              stylesheets using variables, mixins, and nesting.
            </p>
          </div>
        </div>
        <div className="typescript">
          <div className="img-container">
            <img src="./assets/ts.png" alt="typescript" />
          </div>
          <div className="content">
            <h4>TypeScript</h4>
            <p>
              I create robust and maintainable code that easily scales with the
              complexity of any project.
            </p>
          </div>
        </div>
        <div className="backend">
          <div className="img-container">
            <img src="./assets/node.png" alt="nodejs" />
            <img src="./assets/Express.png" alt="express" />
          </div>
          <div className="content">
            <p>
              With Node.js and Express, I build efficient and scalable back-end
              systems, ensuring fast and reliable server-side performance for
              any application.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stack;
