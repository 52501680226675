import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  return (
    <nav>
      <ul>
        {location.pathname !== "/" && (
          <li>
            <NavLink
              to="/"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Home
            </NavLink>
          </li>
        )}
        {location.pathname !== "/tools" && (
          <li>
            <NavLink
              to="/tools"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Tools
            </NavLink>
          </li>
        )}
        {location.pathname !== "/faq" && (
          <li>
            <NavLink
              to="/faq"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Faq
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
