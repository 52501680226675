import React from "react";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "")}>
        <div className="logo">
          <h1>Jeyson Boursault</h1>
        </div>
      </NavLink>
      <Navigation />
    </header>
  );
};

export default Header;
