import React from "react";

const Education = () => {
  return (
    <div className="education">
      <h3>Education</h3>
      <div className="deust">
        <h4>DEUST BCMM - W2D (Web Designer Developer)</h4>
        <h5>Université du Littoral Côte d’Opale (ULCO)</h5>
        <p className="description">
          The DEUST BCMM – W2D (Web Designer and Developer) is a professional
          Bac +2 degree that prepares students for careers in web development,
          integration, and UX/UI design. This program equips students with both
          technical and creative skills needed to excel in the digital industry,
          with a strong focus on immediate job readiness.
        </p>
      </div>
      <div className="licence">
        <h4>Licence Informatique - Développement Web et mobile sécurisé</h4>
        <h5>Lycée et Campus Saint Joseph Saint Martin Boulogne</h5>
        <p className="description">
          The Bachelor's Degree in Computer Science with a focus on Secure Web &
          Mobile Development (Bac +3) is a work-study program that combines
          practical industry experience with academic learning. This
          professional qualification covers key areas such as development,
          programming, networks, and systems administration.
        </p>
      </div>
      <div className="toeic">
        <h4>Toeic</h4>
        <div className="details">
          <p>
            Listening : <span>485/495</span>
          </p>
          <p className="score">
            <span>925/990</span>
          </p>
          <p>
            Reading : <span>440/495</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Education;
