import React from "react";
import Header from "../components/Header";
import Presentation from "../components/Presentation";
import Stack from "../components/Stack";
import Education from "../components/Education";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";
import Project from "../components/Project";

const Home = () => {
  return (
    <div className="main">
      <Header />
      <Presentation />
      <Stack />
      <Project />
      <Education />
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Home;
