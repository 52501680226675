import React from "react";

const Presentation = () => {
  return (
    <div className="presentation">
      <div className="img-container">
        <img src="./assets/jeyson.jpg" alt="me" />
      </div>
      <div className="job">
        <h2>Developer fullstack</h2>
        <p>
          I craft custom apps from the ground up, whether by following detailed
          design sketches or creating original designs myself.
        </p>
      </div>
    </div>
  );
};

export default Presentation;
