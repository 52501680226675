import React from "react";

const Project = () => {
  return (
    <div className="project-container">
      <h3>Selected project</h3>
      <div className="project">
        <h4>Cryptocurrency Tracking Application</h4>
        <a
          href="https://crypto-watch-five.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Try It Out</button>
        </a>
        <ul>
          <li>React</li>
          <li>Sass</li>
        </ul>
      </div>
    </div>
  );
};

export default Project;
